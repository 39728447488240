import raven from 'raven-js';
import React, { useEffect, useState } from 'react';

import ApiClient from '../../helpers/ApiClient';
import { getCampusApiClient } from '../../helpers/getClients';
import { useDispatch, useSelector } from '../../interfaces/State';
import {
  epicClearBackendClientSettings,
  showModal,
  stopBackendSession,
} from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { selectCourseRef, selectIsUserLoggedIn } from '../../redux/selectors';
import ContentAuthorizationService from '../ContentAuthorizationService/ContentAuthorizationService';

function useRestrictedStatus() {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const courseRef = useSelector(selectCourseRef);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isRestricted, setIsRestricted] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserLoggedIn) {
      setLoading(false);
      return;
    }

    getCampusApiClient()
      .execute(ApiClient.endpoint.getCourse(encodeURIComponent(courseRef), {}))
      .toPromise()
      .then(({ body }: { body?: { restricted?: boolean } }) => {
        setIsRestricted(body?.restricted === true);
      })
      .catch((error: unknown) => {
        raven.captureException(error);
      })
      .finally(() => setLoading(false));
  }, [courseRef, isUserLoggedIn]);

  return { isLoading, isRestricted };
}

const AccessControlHandler: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, isRestricted } = useRestrictedStatus();

  useEffect(() => {
    if (isLoading || !isRestricted) {
      return;
    }

    // @ts-expect-error Action is not yet typed
    dispatch(stopBackendSession());
    dispatch(epicClearBackendClientSettings());
    dispatch(showModal({ modal: selectors.MODAL_TYPE.RESTRICTED_CONTENT }));
  }, [dispatch, isLoading, isRestricted]);

  if (isLoading || isRestricted) {
    return null;
  }

  return <ContentAuthorizationService />;
};

export default React.memo(AccessControlHandler);
