/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { Checkmark, Cross } from '@datacamp/waffles/icon';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import { useSelector } from '../../../interfaces/State';
import * as selectors from '../../../redux/selectors';

const ExerciseAiIncorrectSubmissionRating: React.FC = () => {
  const { t } = useTranslation(['translation', 'common']);

  const [aiIncorrectSubmissionsRated, setAiIncorrectSubmissionsRated] =
    useState(false);

  const userId = useSelector(selectors.selectUserSettings).get('id');
  const courseId = useSelector(selectors.selectCourse).get('id');

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'heap_event',
        heap_event_name:
          'Learn - Campus - AI Incorrect Submissions Rating Exercise Completed Shown',
        heap_event_properties: {
          app_id: config.appName,
          event_category: 'learning',
          identity: userId,
          course_id: courseId,
        },
      });
    }
  }, [courseId, userId]);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: tokens.spacing.large,
        alignItems: 'center',
      }}
    >
      {aiIncorrectSubmissionsRated ? (
        <Heading
          css={{
            paddingLeft: tokens.spacing.medium,
            paddingRight: tokens.spacing.medium,
            color: tokens.colors.white,
            lineHeight: tokens.lineHeights.relaxed,
          }}
        >
          {t('Assignment.ExerciseCompleted.AiIncorrectSubmissionRating.rated')}
        </Heading>
      ) : (
        <>
          <Heading
            css={{
              paddingLeft: tokens.spacing.medium,
              paddingRight: tokens.spacing.medium,
              color: tokens.colors.white,
              lineHeight: tokens.lineHeights.relaxed,
            }}
          >
            {t(
              'Assignment.ExerciseCompleted.AiIncorrectSubmissionRating.question',
            )}
          </Heading>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Button
              css={css`
                color: ${tokens.colors.white};
              `}
              data-cy="ai-incorrect-submissions-exercise-completed-yes-button"
              aria-label={startCase(t('yes', { ns: 'common' }))}
              variant="plain"
              onClick={() => setAiIncorrectSubmissionsRated(true)}
              iconLeft={<Checkmark color={tokens.colors.white} />}
            >
              {startCase(t('yes', { ns: 'common' }))}
            </Button>
            <Button
              css={css`
                color: ${tokens.colors.white};
              `}
              data-cy="ai-incorrect-submissions-exercise-completed-no-button"
              aria-label={startCase(t('no', { ns: 'common' }))}
              variant="plain"
              onClick={() => setAiIncorrectSubmissionsRated(true)}
              iconLeft={<Cross color={tokens.colors.red} />}
            >
              {startCase(t('no', { ns: 'common' }))}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ExerciseAiIncorrectSubmissionRating;
