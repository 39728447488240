/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Chapeau } from '@datacamp/waffles/chapeau';
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { Bulb, Flash, Practice } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import type { ReactElement } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import mobileAppImage from '../../assets/images/mobile_app.png';

import DailyStreakScreenWrapper from './DailyStreakScreenWrapper';

const DailyStreakHint: React.FC<{
  icon: ReactElement;
  text: string;
  title: string;
}> = (props) => {
  const { icon, text, title } = props;

  return (
    <Paragraph
      css={{
        fontSize: tokens.fontSizes.medium,
        lineHeight: tokens.lineHeights.relaxed,
        marginBottom: '12px',
        padding: '3px 0 3px 38px',
        position: 'relative',
      }}
    >
      <span
        css={{
          background: tokens.colors.white20,
          borderRadius: tokens.borderRadius.medium,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '26px',
          left: 0,
          padding: tokens.spacing.xsmall,
          position: 'absolute',
          textAlign: 'center',
          top: 0,
          width: '26px',

          '& > *': {
            height: '80%',
            width: '80%',
          },
        }}
      >
        {icon}
      </span>

      <span css={{ fontWeight: tokens.fontWeights.bold }}>{`${title}: `}</span>
      {text}
    </Paragraph>
  );
};

const DailyStreakAppScreen: React.FC<{ onExit: () => void }> = (props) => {
  const { t } = useTranslation();

  const { onExit } = props;

  const autoFocusRef = useRef(null);

  return (
    <DailyStreakScreenWrapper initialFocus={autoFocusRef}>
      <div
        css={{
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr 1fr',
          gap: `${tokens.spacing.xlarge}`,
          [mediaQuery.aboveLarge]: {
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
          },
        }}
      >
        <img
          src={mobileAppImage}
          alt={t('DailyStreakModal.appScreen.scanQRCode')}
          css={{ maxHeight: '30vh', margin: `0 ${tokens.spacing.xlarge}` }}
        />
        <div
          css={{
            maxWidth: '500px',
            margin: `0 ${tokens.spacing.xlarge}`,
            [mediaQuery.aboveLarge]: {
              order: '-1',
            },
          }}
        >
          <Chapeau
            css={{
              color: tokens.colors.green,
              fontSize: tokens.fontSizes.small,
              marginBottom: tokens.spacing.medium,
              textAlign: 'center',
              [mediaQuery.aboveLarge]: { textAlign: 'left' },
            }}
          >
            {t('DailyStreakModal.appScreen.preTitle')}
          </Chapeau>

          <Heading
            css={{
              fontSize: '32px',
              lineHeight: tokens.lineHeights.default,
              marginBottom: tokens.spacing.large,
              textAlign: 'center',
              [mediaQuery.aboveLarge]: { textAlign: 'left' },
            }}
          >
            {t('DailyStreakModal.appScreen.title')}
          </Heading>

          <DailyStreakHint
            icon={<Bulb />}
            title={t('DailyStreakModal.appScreen.hint1pre')}
            text={t('DailyStreakModal.appScreen.hint1')}
          />

          <DailyStreakHint
            icon={<Practice />}
            title={t('DailyStreakModal.appScreen.hint2pre')}
            text={t('DailyStreakModal.appScreen.hint2')}
          />

          <DailyStreakHint
            icon={<Flash />}
            title={t('DailyStreakModal.appScreen.hint3pre')}
            text={t('DailyStreakModal.appScreen.hint3')}
          />

          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              gap: tokens.spacing.medium,
              marginTop: '40px',
              [mediaQuery.aboveLarge]: { justifyContent: 'flex-start' },
            }}
          >
            <Button
              variant="secondary"
              onClick={onExit}
              data-trackid="campus-learn-streak-mobile-maybe-later"
            >
              {t('DailyStreakModal.appScreen.skipCTA')}
            </Button>
          </div>
        </div>
      </div>
    </DailyStreakScreenWrapper>
  );
};

export default DailyStreakAppScreen;
