import { TranslationsProvider } from '@datacamp/le-shared-components';
import { ToastProvider } from '@datacamp/waffles/toast';
import cn from 'classnames';
import raven from 'raven-js';
import React, { Component } from 'react';

import config from '../config';
import ExerciseFooter from '../containers/ExerciseFooter';
import ModalHandler from '../containers/ModalHandler';
import ScreenHandler from '../containers/ScreenHandler';
import { showLearningRecap } from '../helpers/learningRecap';
import type { LearningRecapState } from '../redux/reducers/learningRecap';
import type { PreFetchedDataState } from '../redux/reducers/preFetchedData';
import { BOOT_ERRORS } from '../redux/selectors';

import AccessControlHandler from './AccessControl/AccessControlHandler';
import DailyStreakScreenHandler from './DailyStreak/DailyStreakScreenHandler';
import EditorToggle from './EditorToggle/EditorToggleContainer';
import ErrorPage from './ErrorPage';
import ExerciseHandler from './ExerciseHandler';
import Header from './Header';
import HeadManager from './HeadManager';
import OnboardingMilestones from './OnboardingMilestones';
import SearchLandingPopUp from './SearchLandingPopup';
import ToastOnUserStatusError from './ToastOnUserStatusError';

type Props = {
  authorizationHeader: string | undefined;
  bootError?: string;
  courseId?: number;
  headProps?: any;
  isApplicationBootable: boolean;
  isPreBooted: boolean;
  isUserEnabledForLearningRecap: boolean;
  language: string;
  learningRecap: LearningRecapState;
  onBoot: (...args: any[]) => any;
  preFetchedData: PreFetchedDataState;
  progressIndicatorVisible: boolean;
  showLearningRecapModal: () => void;
  showShowOrSkipRecapModal: () => void;
  uiTheme: 'DARK' | 'LIGHT'; // TODO: PropTypes.shape(HeadManager.propTypes)
  userId?: number;
};

class App extends Component<Props> {
  private userEligibleForLearningRecap({
    courseId,
    userId,
  }: Partial<{ courseId: number; userId: number }>): void {
    if (window.dataLayer && courseId && userId) {
      window.dataLayer.push({
        event: 'heap_event',
        heap_event_name: 'Learn - Campus - User Eligible For Learn Recap',
        heap_event_properties: {
          app_id: config.appName,
          event_category: 'learning',
          identity: userId,
          course_id: courseId,
        },
      });
    }
  }

  componentWillMount(): void {
    const {
      authorizationHeader,
      isApplicationBootable,
      language,
      preFetchedData,
    } = this.props;
    this.props.onBoot({
      preFetchedData,
      isApplicationBootable,
      language,
      authorizationHeader,
    });
  }

  componentDidCatch(error: any): void {
    raven.captureException(error);
  }

  componentDidUpdate(): void {
    if (this.props.isPreBooted && this.props.bootError == null) {
      showLearningRecap({
        courseId: this.props.courseId,
        isUserEnabledForLearningRecap: this.props.isUserEnabledForLearningRecap,
        learningRecap: this.props.learningRecap,
        showLearningRecapModal: this.props.showLearningRecapModal,
        showShowOrSkipRecapModal: this.props.showShowOrSkipRecapModal,
        userEligibleForLearningRecapCallback: () => {
          this.userEligibleForLearningRecap({
            courseId: this.props.courseId,
            userId: this.props.userId,
          });
        },
      });
    }
  }

  render() {
    const {
      bootError,
      headProps,
      isPreBooted,
      progressIndicatorVisible,
      uiTheme,
    } = this.props;
    const wrapperClasses = cn('theme', {
      'progress-indicator--visible': progressIndicatorVisible,
      'theme--light': uiTheme === 'LIGHT',
    });

    let errorProps = {};
    if (bootError === BOOT_ERRORS.LTI_NOT_ACTIVE) {
      errorProps = {
        pageTitle: 'Something went wrong with your edX session',
        title: 'Oops, something went wrong...',
        description:
          ' It looks like something went wrong with your edX session. Please close this page and try again.',
      };
    }

    return (
      <ToastProvider>
        <div className={wrapperClasses}>
          {bootError === null && <HeadManager {...headProps} />}
          {isPreBooted && bootError !== null && <ErrorPage {...errorProps} />}
          {isPreBooted && bootError === null && (
            <TranslationsProvider language={this.props.language}>
              {!config.isServerSideRendering ? <AccessControlHandler /> : null}
              <Header />
              <EditorToggle />
              <ExerciseHandler />
              {progressIndicatorVisible && <ExerciseFooter />}
              <SearchLandingPopUp />
              <ScreenHandler />
              <ModalHandler />
              <DailyStreakScreenHandler />
              <OnboardingMilestones />
              <ToastOnUserStatusError />
            </TranslationsProvider>
          )}
        </div>
      </ToastProvider>
    );
  }
}

export default App;
